export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Account: ['BuyerAccount', 'SupplierAccount'],
    Actor: ['User'],
    Archivable: [
      'ApprovalDelegation',
      'ApproverCustomField',
      'AttachmentCustomField',
      'BankAccount',
      'Category',
      'CategoryCustomField',
      'CategorySet',
      'CheckboxCustomField',
      'CostCentre',
      'CostCentreCustomField',
      'DataSource',
      'DataSourceItem',
      'DataView',
      'DateCustomField',
      'Document',
      'IntegerCustomField',
      'IssuerIntegration',
      'Location',
      'MoneyCustomField',
      'OrganisationUnit',
      'OrganisationUnitCustomField',
      'ReportSchedule',
      'SelectCustomField',
      'SupplierOnboardingContact',
      'TextAreaCustomField',
      'TextCustomField',
      'UrlCustomField',
      'User',
      'Workflow',
    ],
    AssociationCustomFieldValueValue: ['Category', 'CostCentre', 'DataSourceItem', 'OrganisationUnit', 'User'],
    Attachable: [
      'AttachmentCustomFieldValue',
      'BuyerAccount',
      'Document',
      'IncomingEmailAttachment',
      'Report',
      'SupplierAccount',
      'User',
    ],
    Auditable: [
      'ApproverCustomField',
      'AssociationCustomFieldValue',
      'AttachmentCustomField',
      'AttachmentCustomFieldValue',
      'BooleanCustomFieldValue',
      'BuyerAccount',
      'Category',
      'CategoryCustomField',
      'CategorySet',
      'CheckboxCustomField',
      'CostCentre',
      'CostCentreCustomField',
      'DataSource',
      'DataSourceItem',
      'DataView',
      'DataViewField',
      'DateCustomField',
      'DateTimeCustomFieldValue',
      'Document',
      'IntegerCustomField',
      'IntegerCustomFieldValue',
      'MoneyCustomField',
      'MoneyCustomFieldValue',
      'OrganisationUnit',
      'OrganisationUnitCustomField',
      'PurchaseOrder',
      'PurchaseRequestItem',
      'ReportSchedule',
      'SelectCustomField',
      'StringCustomFieldValue',
      'SupplierAccount',
      'TextAreaCustomField',
      'TextCustomField',
      'UrlCustomField',
      'User',
    ],
    Commentable: [
      'Approval',
      'ApprovalDelegation',
      'CardSubscription',
      'Document',
      'PurchaseOrder',
      'PurchaseRequestItem',
    ],
    CustomField: [
      'ApproverCustomField',
      'AttachmentCustomField',
      'CategoryCustomField',
      'CheckboxCustomField',
      'CostCentreCustomField',
      'DateCustomField',
      'IntegerCustomField',
      'MoneyCustomField',
      'OrganisationUnitCustomField',
      'SelectCustomField',
      'TextAreaCustomField',
      'TextCustomField',
      'UrlCustomField',
    ],
    CustomFieldValue: [
      'AssociationCustomFieldValue',
      'AttachmentCustomFieldValue',
      'BooleanCustomFieldValue',
      'DateTimeCustomFieldValue',
      'IntegerCustomFieldValue',
      'MoneyCustomFieldValue',
      'StringCustomFieldValue',
    ],
    CustomFieldValuesParent: [
      'Category',
      'CostCentre',
      'DataSourceItem',
      'Document',
      'OrganisationUnit',
      'PurchaseRequestItem',
      'User',
    ],
    DataScopingRules: [
      'ApproverCustomField',
      'CategoryCustomField',
      'CostCentreCustomField',
      'OrganisationUnitCustomField',
      'SelectCustomField',
    ],
    Documentable: ['CardSubscription', 'PurchaseOrder', 'Transaction'],
    Draftable: ['Document', 'PurchaseRequestItem'],
    EventSubject: [
      'Approval',
      'Attachment',
      'BuyerAccount',
      'Comment',
      'Document',
      'IncomingEmail',
      'PurchaseOrder',
      'PurchaseRequestItem',
      'SupplierAccount',
      'Transaction',
      'User',
      'VirtualCard',
    ],
    FlowStep: [
      'FlowActionApproveBy',
      'FlowActionAutomaticApprove',
      'FlowActionAutomaticReject',
      'FlowActionComment',
      'FlowActionCreatePurchaseOrder',
      'FlowStepCondition',
    ],
    FlowStepAction: [
      'FlowActionApproveBy',
      'FlowActionAutomaticApprove',
      'FlowActionAutomaticReject',
      'FlowActionComment',
      'FlowActionCreatePurchaseOrder',
    ],
    FlowStepResultValueRecord: [
      'Address',
      'Approval',
      'ApprovalDelegation',
      'BuyerAccount',
      'CardSubscription',
      'Category',
      'CostCentre',
      'DataSource',
      'DataSourceItem',
      'Document',
      'DocumentParseResult',
      'InvoicePayment',
      'IssuerIntegration',
      'Merchant',
      'OrganisationUnit',
      'PurchaseOrder',
      'PurchaseRequestItem',
      'SupplierAccount',
      'Transaction',
      'User',
      'VirtualCard',
    ],
    FormableParent: ['DataSource'],
    GlobalIDField: [
      'Activity',
      'Address',
      'Approval',
      'ApprovalDelegation',
      'ApprovalsUser',
      'ApproverCustomField',
      'AssociationCustomFieldValue',
      'Attachment',
      'AttachmentCustomField',
      'AttachmentCustomFieldValue',
      'BankAccount',
      'Blob',
      'BooleanCustomFieldValue',
      'BuyerAccount',
      'CardAuthorisation',
      'CardSubscription',
      'Category',
      'CategoryCustomField',
      'CategorySet',
      'CheckboxCustomField',
      'Comment',
      'CompanyLookup',
      'CostCentre',
      'CostCentreCustomField',
      'DataFilter',
      'DataPath',
      'DataScopingRule',
      'DataSource',
      'DataSourceItem',
      'DataView',
      'DataViewField',
      'DateCustomField',
      'DateTimeCustomFieldValue',
      'Document',
      'DocumentParseResult',
      'DocumentTransaction',
      'FlowActionApproveBy',
      'FlowActionAutomaticApprove',
      'FlowActionAutomaticReject',
      'FlowActionComment',
      'FlowActionCreatePurchaseOrder',
      'FlowStepCondition',
      'FlowStepResult',
      'FlowStepResultValue',
      'Inbox',
      'IncomingEmail',
      'IncomingEmailAttachment',
      'IndustryCode',
      'IntegerCustomField',
      'IntegerCustomFieldValue',
      'InvoicePayment',
      'IssuerIntegration',
      'IssuerIntegrationConsumer',
      'IssuerIntegrationRule',
      'Location',
      'Me',
      'Merchant',
      'MerchantCategoryCode',
      'MoneyCustomField',
      'MoneyCustomFieldValue',
      'Notification',
      'OrganisationUnit',
      'OrganisationUnitCustomField',
      'PublicAddress',
      'PurchaseOrder',
      'PurchaseRequestItem',
      'ReferenceAssociation',
      'Report',
      'ReportSchedule',
      'Schedule',
      'SelectCustomField',
      'SpendLimit',
      'StringCustomFieldValue',
      'SupplierAccount',
      'SupplierOnboardingContact',
      'SupplierOnboardingInvitation',
      'TextAreaCustomField',
      'TextCustomField',
      'Transaction',
      'UrlCustomField',
      'User',
      'VirtualCard',
      'Workflow',
      'WorkflowRun',
    ],
    HasActivities: [
      'Approval',
      'ApprovalDelegation',
      'ApproverCustomField',
      'AttachmentCustomField',
      'BuyerAccount',
      'CardSubscription',
      'Category',
      'CategoryCustomField',
      'CategorySet',
      'CheckboxCustomField',
      'Comment',
      'CostCentre',
      'CostCentreCustomField',
      'DataSource',
      'DataSourceItem',
      'DataView',
      'DateCustomField',
      'Document',
      'IntegerCustomField',
      'InvoicePayment',
      'IssuerIntegration',
      'IssuerIntegrationConsumer',
      'IssuerIntegrationRule',
      'Location',
      'MoneyCustomField',
      'OrganisationUnit',
      'OrganisationUnitCustomField',
      'PurchaseOrder',
      'PurchaseRequestItem',
      'Report',
      'ReportSchedule',
      'SelectCustomField',
      'SupplierAccount',
      'TextAreaCustomField',
      'TextCustomField',
      'Transaction',
      'UrlCustomField',
      'User',
      'VirtualCard',
      'Workflow',
    ],
    HasAncestry: ['Category', 'IndustryCode', 'OrganisationUnit'],
    Inboxable: ['CardSubscription'],
    ManuallyOrderable: [
      'ApproverCustomField',
      'AttachmentCustomField',
      'CategoryCustomField',
      'CheckboxCustomField',
      'CostCentreCustomField',
      'DataScopingRule',
      'DataViewField',
      'DateCustomField',
      'IntegerCustomField',
      'IssuerIntegrationRule',
      'MoneyCustomField',
      'OrganisationUnitCustomField',
      'SelectCustomField',
      'TextAreaCustomField',
      'TextCustomField',
      'UrlCustomField',
    ],
    Node: [
      'Activity',
      'Address',
      'Approval',
      'ApprovalDelegation',
      'ApprovalsUser',
      'ApproverCustomField',
      'AssociationCustomFieldValue',
      'Attachment',
      'AttachmentCustomField',
      'AttachmentCustomFieldValue',
      'BankAccount',
      'Blob',
      'BooleanCustomFieldValue',
      'BuyerAccount',
      'CardAuthorisation',
      'CardSubscription',
      'Category',
      'CategoryCustomField',
      'CategorySet',
      'CheckboxCustomField',
      'Comment',
      'CompanyLookup',
      'CostCentre',
      'CostCentreCustomField',
      'DataFilter',
      'DataPath',
      'DataScopingRule',
      'DataSource',
      'DataSourceItem',
      'DataView',
      'DataViewField',
      'DateCustomField',
      'DateTimeCustomFieldValue',
      'Document',
      'DocumentParseResult',
      'DocumentTransaction',
      'FlowActionApproveBy',
      'FlowActionAutomaticApprove',
      'FlowActionAutomaticReject',
      'FlowActionComment',
      'FlowActionCreatePurchaseOrder',
      'FlowStepCondition',
      'FlowStepResult',
      'FlowStepResultValue',
      'Inbox',
      'IncomingEmail',
      'IncomingEmailAttachment',
      'IndustryCode',
      'IntegerCustomField',
      'IntegerCustomFieldValue',
      'InvoicePayment',
      'IssuerIntegration',
      'IssuerIntegrationConsumer',
      'IssuerIntegrationRule',
      'Location',
      'Me',
      'Merchant',
      'MerchantCategoryCode',
      'MoneyCustomField',
      'MoneyCustomFieldValue',
      'Notification',
      'OrganisationUnit',
      'OrganisationUnitCustomField',
      'PublicAddress',
      'PurchaseOrder',
      'PurchaseRequestItem',
      'ReferenceAssociation',
      'Report',
      'ReportSchedule',
      'Schedule',
      'SelectCustomField',
      'SpendLimit',
      'StringCustomFieldValue',
      'SupplierAccount',
      'SupplierOnboardingContact',
      'SupplierOnboardingInvitation',
      'TextAreaCustomField',
      'TextCustomField',
      'Transaction',
      'UrlCustomField',
      'User',
      'VirtualCard',
      'Workflow',
      'WorkflowRun',
    ],
    ReferenceAssociable: [
      'Address',
      'Category',
      'CostCentre',
      'DataSourceItem',
      'IssuerIntegration',
      'OrganisationUnit',
      'User',
    ],
    RelationExists: [
      'Activity',
      'Address',
      'Approval',
      'ApprovalDelegation',
      'ApprovalsUser',
      'ApproverCustomField',
      'AssociationCustomFieldValue',
      'Attachment',
      'AttachmentCustomField',
      'AttachmentCustomFieldValue',
      'BankAccount',
      'Blob',
      'BooleanCustomFieldValue',
      'BuyerAccount',
      'CardAuthorisation',
      'CardSubscription',
      'Category',
      'CategoryCustomField',
      'CategorySet',
      'CheckboxCustomField',
      'Comment',
      'CompanyLookup',
      'CostCentre',
      'CostCentreCustomField',
      'DataFilter',
      'DataPath',
      'DataScopingRule',
      'DataSource',
      'DataSourceItem',
      'DataView',
      'DataViewField',
      'DateCustomField',
      'DateTimeCustomFieldValue',
      'Document',
      'DocumentParseResult',
      'DocumentTransaction',
      'FlowActionApproveBy',
      'FlowActionAutomaticApprove',
      'FlowActionAutomaticReject',
      'FlowActionComment',
      'FlowActionCreatePurchaseOrder',
      'FlowStepCondition',
      'FlowStepResult',
      'FlowStepResultValue',
      'Inbox',
      'IncomingEmail',
      'IncomingEmailAttachment',
      'IndustryCode',
      'IntegerCustomField',
      'IntegerCustomFieldValue',
      'InvoicePayment',
      'IssuerIntegration',
      'IssuerIntegrationConsumer',
      'IssuerIntegrationRule',
      'Location',
      'Me',
      'Merchant',
      'MerchantCategoryCode',
      'MoneyCustomField',
      'MoneyCustomFieldValue',
      'Notification',
      'OrganisationUnit',
      'OrganisationUnitCustomField',
      'PublicAddress',
      'PurchaseOrder',
      'PurchaseRequestItem',
      'ReferenceAssociation',
      'Report',
      'ReportSchedule',
      'Schedule',
      'SelectCustomField',
      'SpendLimit',
      'StringCustomFieldValue',
      'SupplierAccount',
      'SupplierOnboardingContact',
      'SupplierOnboardingInvitation',
      'TextAreaCustomField',
      'TextCustomField',
      'Transaction',
      'UrlCustomField',
      'User',
      'VirtualCard',
      'Workflow',
      'WorkflowRun',
    ],
    SpendLimitable: ['OrganisationUnit', 'User'],
    StringFragment: ['ReferenceFragment', 'TextFragment'],
    Subscribable: [
      'Document',
      'PurchaseOrder',
      'PurchaseRequestItem',
      'Report',
      'ReportSchedule',
      'SupplierOnboardingInvitation',
    ],
    Timestamps: [
      'Activity',
      'Address',
      'Approval',
      'ApprovalDelegation',
      'ApprovalsUser',
      'ApproverCustomField',
      'AssociationCustomFieldValue',
      'Attachment',
      'AttachmentCustomField',
      'AttachmentCustomFieldValue',
      'BankAccount',
      'Blob',
      'BooleanCustomFieldValue',
      'BuyerAccount',
      'CardAuthorisation',
      'CardSubscription',
      'Category',
      'CategoryCustomField',
      'CategorySet',
      'CheckboxCustomField',
      'Comment',
      'CompanyLookup',
      'CostCentre',
      'CostCentreCustomField',
      'DataFilter',
      'DataPath',
      'DataScopingRule',
      'DataSource',
      'DataSourceItem',
      'DataView',
      'DataViewField',
      'DateCustomField',
      'DateTimeCustomFieldValue',
      'DeviceRegistration',
      'Document',
      'DocumentParseResult',
      'DocumentTransaction',
      'FlowActionApproveBy',
      'FlowActionAutomaticApprove',
      'FlowActionAutomaticReject',
      'FlowActionComment',
      'FlowActionCreatePurchaseOrder',
      'FlowStepCondition',
      'FlowStepResult',
      'FlowStepResultValue',
      'Inbox',
      'IncomingEmailAttachment',
      'IndustryCode',
      'IntegerCustomField',
      'IntegerCustomFieldValue',
      'InvoicePayment',
      'IssuerIntegration',
      'IssuerIntegrationConsumer',
      'IssuerIntegrationRule',
      'Location',
      'Merchant',
      'MoneyCustomField',
      'MoneyCustomFieldValue',
      'Notification',
      'OrganisationUnit',
      'OrganisationUnitCustomField',
      'PurchaseOrder',
      'PurchaseRequestItem',
      'ReferenceAssociation',
      'Report',
      'ReportSchedule',
      'Schedule',
      'SelectCustomField',
      'SpendLimit',
      'StringCustomFieldValue',
      'SupplierAccount',
      'SupplierOnboardingContact',
      'SupplierOnboardingInvitation',
      'TextAreaCustomField',
      'TextCustomField',
      'Transaction',
      'UrlCustomField',
      'User',
      'VirtualCard',
      'Workflow',
      'WorkflowRun',
    ],
    WorkflowRecord: ['PurchaseRequestItem'],
  },
};
export default result;
