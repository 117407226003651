import common from '../../../../locales/da/common.json';
import component__confirmation_modal from '../../../../locales/da/component__confirmation_modal.json';
import component__custom_field_sidebar from '../../../../locales/da/component__custom_field_sidebar.json';
import component__custom_field_value_modal from '../../../../locales/da/component__custom_field_value_modal.json';
import component__error_boundary from '../../../../locales/da/component__error_boundary.json';
import component__files_field from '../../../../locales/da/component__files_field.json';
import component__phone_verification from '../../../../locales/da/component__phone_verification.json';
import component__preview_sidebar from '../../../../locales/da/component__preview_sidebar.json';
import component__status_history from '../../../../locales/da/component__status_history.json';
import component__user_sidebar from '../../../../locales/da/component__user_sidebar.json';
import component__workflow_run from '../../../../locales/da/component__workflow_run.json';
import container__approval from '../../../../locales/da/container__approval.json';
import container__approval_flow from '../../../../locales/da/container__approval_flow.json';
import container__approvals from '../../../../locales/da/container__approvals.json';
import container__new_request_details from '../../../../locales/da/container__new_request_details.json';
import container__new_request_start from '../../../../locales/da/container__new_request_start.json';
import container__overview from '../../../../locales/da/container__overview.json';
import container__request from '../../../../locales/da/container__request.json';
import container__subscription from '../../../../locales/da/container__subscription.json';
import container__subscriptions from '../../../../locales/da/container__subscriptions.json';
import container__suppliers from '../../../../locales/da/container__suppliers.json';
import container__user from '../../../../locales/da/container__user.json';
import container__users from '../../../../locales/da/container__users.json';
import container__workflows from '../../../../locales/da/container__workflows.json';
import enums from '../../../../locales/da/enums.json';
import interfaces from '../../../../locales/da/interfaces.json';
import landing from '../../../../locales/da/landing.json';
import layout from '../../../../locales/da/layout.json';
import rrule from '../../../../locales/da/rrule.json';
import supplier_app from '../../../../locales/da/supplier_app.json';
import supplier_container__overview from '../../../../locales/da/supplier_container__overview.json';
import toasts from '../../../../locales/da/toasts.json';

export const da = {
  common,
  component__confirmation_modal,
  component__custom_field_sidebar,
  component__custom_field_value_modal,
  component__error_boundary,
  component__files_field,
  component__phone_verification,
  component__preview_sidebar,
  component__status_history,
  component__user_sidebar,
  component__workflow_run,
  container__approval,
  container__approval_flow,
  container__approvals,
  container__new_request_details,
  container__new_request_start,
  container__overview,
  container__request,
  container__subscription,
  container__subscriptions,
  container__suppliers,
  container__user,
  container__users,
  container__workflows,
  enums,
  interfaces,
  landing,
  layout,
  rrule,
  supplier_app,
  supplier_container__overview,
  toasts,
};
